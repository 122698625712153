// @import "../../global.scss";
// li{
//     font-size: 14px;
//     margin-right: 50px;
//     padding: 7px;
//     border-radius: 10px;

//     cursor: pointer;
   

//     &:hover{
//       background-color: #2C3333;
//     }

//     &.active{
//       background-color: $mainColor;
//       color: white;
//     }
//   }


  

@import "../../global.scss";

.portfolioList {
  font-size: 14px;
  margin-right: 50px;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;

  @include mobile{
    margin-right: 20px;
  }

  &.active {
    background-color: $mainColor;
    color: white;
  }
}